import React, { createContext, useReducer } from "react";
import { reducerActionType } from "../types/reducerActionType";
import { PlayerType, playerInitialState, playerReducer } from "../reducers/playerReducer";

type initialStateType = {
  player: PlayerType;
}

type GlobalContextType = {
  state: initialStateType;
  dispatch: React.Dispatch<any>;
}

const initialState = {
  player: playerInitialState,
}

export const GlobalContext = createContext<GlobalContextType>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (state: initialStateType, action: reducerActionType) => ({
  player: playerReducer(state.player, action),
});

export const GlobalContextProvider: any = ({ children }: {children: JSX.Element}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}