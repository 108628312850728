import { useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import styles from './styles.module.css';
import Song from '../song';

export default function Collection() {
    const { state, dispatch } = useContext(GlobalContext);

    const handlePlay = (track: any, index: any) => {
        dispatch({
            type: 'PLAYER_CHANGE_CURRENTMUSIC',
            payload: {
                currentMusic: track
            }
       });

       dispatch({
            type: 'PLAYER_CHANGE_ISPLAYING',
            payload: {
                isPlaying: true
            }
        });

        dispatch({
            type: 'PLAYER_CHANGE_CURRENTID',
            payload: {
                currentId: index
            }
        });
    }

    return (
        <div className={`${styles.collection}`}>
            {state.player.props && state.player.props.map((item: any, i: any) => (
                <div key={`a${item.filesKey}`} onClick={() => handlePlay(item, i)}>
                    <Song trackInfo={item} active={state.player.currentMusic === item} number={i+1} />
                </div>
            ))}
        </div>
    )
}