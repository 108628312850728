import styles from './styles.module.css';
import { ReactComponent as Play } from '../../assets/playingList.svg';
import ListCover from '../../assets/listCover.png';

export default function Song({ trackInfo, active, number } : { trackInfo: any, active:boolean, number: number }) {
    return (
        <div key={`b${number}`} className={`${styles.song} ${active && styles.active}`}>
            <div className={styles.infos}>
                {active && <Play className={styles.playIcon} />}
                {!active && <span className={styles.number}>{number}</span>}
                <img src={trackInfo.artURL} width="44px" height="44px" loading="lazy" alt="" />
                <div className={styles.titles}>
                    <span className={styles.mTitle}>{trackInfo.title}</span>
                    <span className={styles.mArtist}>{trackInfo.artist}</span>
                </div>
            </div>
            {/* <span className={styles.albumName}>{trackInfo.album} • {trackInfo.year}</span> */}
            <span className={styles.duration}>{trackInfo.duration}</span>
        </div>
    )
}