import styles from './styles.module.css';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as UserMenu } from '../../assets/userMenu.svg';
import UserImage from '../../assets/user.png';

export default function Sidebar() {
    return (
        <div className={styles.sidebar}>
            <div className={styles.menu}>
                <button>
                    <HomeIcon className={styles.homeIcon} />
                    Home
                </button>
            </div>
            <div className={styles.userArea}>
                <div className={styles.userProfile}>
                    <img src={UserImage} alt="" />
                    <span>Username</span>
                </div>
                <UserMenu className={styles.btnMenu} />
            </div>
        </div>
    )
}