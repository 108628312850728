import { useState, useRef, useEffect, useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProgressBar from '../progressBar';
import styles from './styles.module.css';
import { ReactComponent as Random } from '../../assets/random.svg';
import { ReactComponent as RandomActive } from '../../assets/randomActive.svg';
import { ReactComponent as Back } from '../../assets/back.svg';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Pause } from '../../assets/pause.svg';
import { ReactComponent as Forward } from '../../assets/forward.svg';
import { ReactComponent as Repeat } from '../../assets/repeat.svg';
import { ReactComponent as RepeatActive } from '../../assets/repeatActive.svg';
import { ReactComponent as Volume } from '../../assets/volume.svg';
import { ReactComponent as Muted } from '../../assets/muted.svg';

export default function Player() {
    const { state, dispatch } = useContext(GlobalContext);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const audioPlayer = useRef() as any;
    const volumeRef = useRef() as any;

    const onChange = (e: any) => {
        const audio = audioPlayer.current;
        audio.currentTime = (audio.duration / 100) * e.target.value;
        setPercentage(e.target.value);
    }

    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if(!prevValue){
            audioPlayer.current.play();
        } else {
            audioPlayer.current.pause();
        }
    }

    const getCurrDuration = (e: any) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2);
        const time = e.currentTarget.currentTime;
        setPercentage(+percent);
        setCurrentTime(time.toFixed(2));
    }

    useEffect(() => {
        if(state.player.currentMusic){
            setIsPlaying(true);
            audioPlayer.current.play();
            volumeRef.current.value = 1;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.player.currentMusic]);

    useEffect(() => {
        const seconds = Math.floor(audioPlayer.current.duration);
        setDuration(seconds);
    }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

    const generateRandom = (): any => {
        var num = Math.floor(Math.random() * (state.player.totalMusics - 0) + 0);
        return (num === state.player.currentId) ? generateRandom() : num;
    }

    const calculateTime = (secs: number | string) => {
        const minutes = Math.floor(Number(secs) / 60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(Number(secs) % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const changeVolume = () => {
        volumeRef.current.style.setProperty('--seek-before-width', `${volumeRef.current.value / state.player.volume * 100}%`);
        audioPlayer.current.volume = parseFloat(`${volumeRef.current.value / state.player.volume * 100}%`) / 100.0;
    }

    const prevMusic = () => {
        if((state.player.currentId - 1) < 0){
            return;
        }

        dispatch({
            type: 'PLAYER_CHANGE_CURRENTMUSIC',
            payload: {
                currentMusic: state.player.props[state.player.currentId-1]
            }
       });

       dispatch({
        type: 'PLAYER_CHANGE_CURRENTID',
        payload: {
            currentId: state.player.currentId-1
        }
    });
    }

    const nextMusicAuto = () => {
        if(state.player.isRepeatCurrent){
            audioPlayer.currentTime = 0;
            audioPlayer.current.play();
 
            return;
        }

        if(state.player.isRandom){
            let nextIndex = generateRandom();

            dispatch({
                type: 'PLAYER_CHANGE_CURRENTMUSIC',
                payload: {
                    currentMusic: state.player.props[nextIndex]
                }
           });
    
           dispatch({
                type: 'PLAYER_CHANGE_CURRENTID',
                payload: {
                    currentId: nextIndex
                }
            });

            return;
        }

         if((state.player.currentId + 1) > (state.player.totalMusics-1)){
            if(!state.player.isRepeatCurrent){
                setIsPlaying(false);
            }

            return;
        }

        dispatch({
            type: 'PLAYER_CHANGE_CURRENTMUSIC',
            payload: {
                currentMusic: state.player.props[state.player.currentId+1]
            }
       });

       dispatch({
            type: 'PLAYER_CHANGE_CURRENTID',
            payload: {
                currentId: state.player.currentId+1
            }
        });
    }

    const nextMusic = () => {
        if(state.player.isRandom){
            let nextIndex = generateRandom();

            dispatch({
                type: 'PLAYER_CHANGE_CURRENTMUSIC',
                payload: {
                    currentMusic: state.player.props[nextIndex]
                }
           });
    
           dispatch({
                type: 'PLAYER_CHANGE_CURRENTID',
                payload: {
                    currentId: nextIndex
                }
            });

            return;
        }

        if((state.player.currentId + 1) > (state.player.totalMusics-1)){
            return;
        }

        dispatch({
            type: 'PLAYER_CHANGE_CURRENTMUSIC',
            payload: {
                currentMusic: state.player.props[state.player.currentId+1]
            }
       });

       dispatch({
            type: 'PLAYER_CHANGE_CURRENTID',
            payload: {
                currentId: state.player.currentId+1
            }
        });
    }

    const repeatCurrent = () => {
        dispatch({
            type: 'PLAYER_CHANGE_ISRANDOM',
            payload: {
                isRandom: false
            }
        });

        if(state.player.isRepeatCurrent){
            dispatch({
                type: 'PLAYER_CHANGE_ISREPEATCURRENT',
                payload: {
                    isRepeatCurrent: false
                }
            });
        } else {
            dispatch({
                type: 'PLAYER_CHANGE_ISREPEATCURRENT',
                payload: {
                    isRepeatCurrent: true
                }
            });
        }
    }

    const randomMusic = () => {
        dispatch({
            type: 'PLAYER_CHANGE_ISREPEATCURRENT',
            payload: {
                isRepeatCurrent: false
            }
        });

        if(state.player.isRandom){
            dispatch({
                type: 'PLAYER_CHANGE_ISRANDOM',
                payload: {
                    isRandom: false
                }
            });
        } else {
            dispatch({
                type: 'PLAYER_CHANGE_ISRANDOM',
                payload: {
                    isRandom: true
                }
            });
        }
    }

    const handleMuted = () => {
        if(state.player.isMuted){
            dispatch({
                type: 'PLAYER_CHANGE_ISMUTED',
                payload: {
                    isMuted: false
                }
            });
            audioPlayer.current.volume = 1;
        } else {
            dispatch({
                type: 'PLAYER_CHANGE_ISMUTED',
                payload: {
                    isMuted: true
                }
            });
            audioPlayer.current.volume = 0;
        }
    }

    return (
        <>
            <div className={styles.player}>
                <div className={styles.wait}>
                    <ProgressBar percentage={percentage} onChange={onChange} />
                </div>
                <audio ref={audioPlayer} src={state.player.currentMusic.mp3URL} preload="metadata" autoPlay onEnded={nextMusicAuto} onTimeUpdate={getCurrDuration}></audio>
                <div className={styles.songInfo}>
                    <img src={state.player.currentMusic.artURL} width="100px" height="100px" loading="lazy" alt="" />
                    <div>
                        <span className={styles.musicTitle}>{state.player.currentMusic.title}</span>
                        <span className={styles.artistName}>{state.player.currentMusic.artist}</span>
                    </div>
                </div>
                <div className={styles.controls}>
                    {!state.player.isRandom && <Random onClick={randomMusic} className={`${styles.cursorPointer} ${styles.random}`} />}
                    {state.player.isRandom && <RandomActive onClick={randomMusic} className={styles.cursorPointer} />}
                    <Back onClick={prevMusic} className={`${styles.cursorPointer} ${styles.back}`} />
                    {!isPlaying && <Play onClick={togglePlayPause} className={`${styles.cursorPointer} ${styles.play}`} />}
                    {isPlaying && <Pause onClick={togglePlayPause} className={`${styles.cursorPointer} ${styles.pause}`} />}
                    <Forward onClick={nextMusic} className={`${styles.cursorPointer} ${styles.forward}`} />
                    {!state.player.isRepeatCurrent && <Repeat onClick={repeatCurrent} className={`${styles.cursorPointer} ${styles.repeat}`} />}
                    {state.player.isRepeatCurrent && <RepeatActive onClick={repeatCurrent} className={styles.cursorPointer} />}
                </div>
                <div className={styles.musicRange}>
                    <div className={styles.currentTime}>{calculateTime(currentTime)}</div>
                    
                        <ProgressBar percentage={percentage} onChange={onChange} />
                    
                    <div className={styles.duration}>{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
                </div>
                <div className={styles.musicVolume}>
                    
                    {!state.player.isMuted && <Volume onClick={handleMuted} className={styles.cursorPointer} />}
                    {state.player.isMuted && <Muted onClick={handleMuted} className={styles.cursorPointer} />}
                    <div className={styles.volBar}>
                        <input type="range" className={styles.progressBar} defaultValue="0" ref={volumeRef} onChange={changeVolume} />
                    </div>
                </div>
            </div>
        </>
    )
}