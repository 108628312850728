import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter } from 'react-router-dom';
import { GlobalContextProvider } from './contexts/GlobalContext';

const firebaseConfig = {
  apiKey: "AIzaSyChjD5i1CI-NMduoUZMfI_hwui1fOK6qlA",
  authDomain: "webp-359ce.firebaseapp.com",
  projectId: "webp-359ce",
  storageBucket: "webp-359ce.appspot.com",
  databaseURL: "webp-359ce-default-rtdb.firebaseio.com",
  messagingSenderId: "205416385461",
  appId: "1:205416385461:web:0fb31b8646fb26416167fc",
  measurementId: "G-4S4YC2MX0J"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
