import { reducerActionType } from "../types/reducerActionType";

type PlayerProps = {
  [key: string]: any;
}

export type PlayerType = {
  props: PlayerProps;
  isPlaying: boolean;
  currentMusic: any;
  totalMusics: number;
  currentId: number;
  isRepeatCurrent: boolean;
  isRepeatAll: boolean;
  isRandom: boolean;
  randomList: [];
  volume: number;
  isMuted: boolean;
}

export const playerInitialState: PlayerType = {
  props: [],
  isPlaying: false,
  currentMusic: null,
  totalMusics: 0,
  currentId: 0,
  isRepeatCurrent: false,
  isRepeatAll: false,
  isRandom: false,
  randomList: [],
  volume: 100,
  isMuted: false
}

export const playerReducer = (state: PlayerType, action: reducerActionType) => {
  switch (action.type) {
    case 'PLAYER_CHANGE_PROPS':
      return { ...state, props: action.payload.props };
    case 'PLAYER_CHANGE_ISPLAYING':
      return { ...state, isPlaying: action.payload.isPlaying };
    case 'PLAYER_CHANGE_CURRENTMUSIC':
      return { ...state, currentMusic: action.payload.currentMusic };
    case 'PLAYER_CHANGE_TOTALMUSICS':
      return { ...state, totalMusics: action.payload.totalMusics };
    case 'PLAYER_CHANGE_CURRENTID':
      return { ...state, currentId: action.payload.currentId };
    case 'PLAYER_CHANGE_ISREPEATCURRENT':
      return { ...state, isRepeatCurrent: action.payload.isRepeatCurrent };
    case 'PLAYER_CHANGE_ISREPEATALL':
      return { ...state, isRepeatAll: action.payload.isRepeatAll };
    case 'PLAYER_CHANGE_ISRANDOM':
      return { ...state, isRandom: action.payload.isRandom };
    case 'PLAYER_CHANGE_RANDOMLIST':
      return { ...state, randomList: action.payload.randomList };
    case 'PLAYER_CHANGE_VOLUME':
      return { ...state, volume: action.payload.volume };
    case 'PLAYER_CHANGE_ISMUTED':
      return { ...state, isMuted: action.payload.isMuted };
  }

  return state;
}