import { Route, Routes } from "react-router-dom"
import Login from "./components/login";
import Home from "./components/home";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}