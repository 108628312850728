import styles from './styles.module.css';
import { ReactComponent as Clock } from '../../assets/clock.svg';
import { ReactComponent as Search } from '../../assets/search.svg';

export default function Header() {
    return (
        <>
            <div className={styles.header}>
                {/* <div className={styles.searchArea}>
                    <input type="text" placeholder='Buscar' />
                    <Search className={styles.search} />
                </div> */}
                <span className={styles.title}>Coleção</span>
                <div className={styles.songsHeader}>
                    <div>
                        <span>#</span>
                        <span className={styles.songsHeaderTitle}>TÍTULO</span>
                    </div>
                    <span>ÁLBUM</span>
                    <Clock />
                </div>
            </div>
        </>
    )
}